<template>
  <div class="inner-div">
    <VasionSchedulersPage title="Schedule OCR" />
  </div>
</template>

<script>
export default {
  name: 'ScheduleOcr',
}
</script>

<style lang="scss" scoped>

  .inner-div {
    padding-top: 8px;
    margin-right: 8px;
    margin-left: 16px;
  }

</style>
